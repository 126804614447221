import React, { memo, PropsWithChildren } from 'react';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export interface SliderProps {
  slidesPerView?: number;
  spaceBetween?: number;
  pagination?: boolean;
  navigation?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
}

function Slider({
  slidesPerView = 3,
  spaceBetween = 35,
  navigation = false,
  pagination = false,
  autoPlay = true,
  loop = false,
  children,
}: PropsWithChildren<SliderProps>) {
  const checkPagination = () => {
    if (navigation && React.Children.count(children) > slidesPerView) {
      return true;
    }
    return false;
  };

  const showPagination = checkPagination();

  const swiperProps = {
    autoHeight: true,
    ...(Boolean(autoPlay) && {
      autoPlay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    }),
    allowTouchMove: true,
    ...(Boolean(showPagination) && { navigation }),
    ...(Boolean(pagination) && {
      pagination: {
        clickable: true,
      },
    }),
    slidesPerView,
    spaceBetween,
    resizeObserver: true,
    updateOnWindowResize: true,
    loop,
  };

  return (
    <Swiper {...swiperProps}>
      {React.Children.map(children, (child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}

export default memo(Slider);
