/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import clsx from 'clsx';
import { CustomerReviewsProps } from 'interfaces/cms/content';
import ParagraphHeader from 'components/ParagraphHeader';
import Swiper from 'components/common/Swiper';
import useMediaQuery from 'hooks/useMediaQuery';
import Script from 'next/script';
import CustomerReview from './Review';

function ReviewData({
  reviews,
}: Readonly<{ reviews: CustomerReviewsProps['reviews'] }>) {
  const [isMobile] = useMediaQuery();
  if (!reviews) return null;
  const slidesPerViewVariant = isMobile ? 1 : 3;

  if (isMobile && reviews.length < 2) {
    return (
      <div className="flex items-center justify-center py-0 px-[0.625rem]">
        {reviews.map((review) => (
          <CustomerReview {...review} key={review.id} />
        ))}
      </div>
    );
  }

  if (!isMobile && reviews.length < 4) {
    return (
      <div className="flex items-center justify-center">
        {reviews.map((review) => (
          <div className="p-[0.9375rem] w-[100%] max-w-[20rem]" key={review.id}>
            <CustomerReview {...review} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <Swiper slidesPerView={slidesPerViewVariant} navigation pagination>
      {reviews.map((review) => (
        <CustomerReview {...review} key={review.id} />
      ))}
    </Swiper>
  );
}

function CustomerReviews({
  header,
  reviews,
  background,
  productName,
  featureImageUrl,
  brand,
  productDescription,
  averageRatingValue,
  totalReviewCount,
}: CustomerReviewsProps) {
  function getReviewStructuredData() {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: productName,
      image: featureImageUrl,
      brand: {
        '@type': 'Brand',
        name: brand,
      },
      description: productDescription,
      reviews: reviews?.map(({ review, rating, customerName }) => ({
        '@type': 'Review',
        reviewBody: review,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: rating,
          bestRating: 5,
        },
        author: {
          '@type': 'Person',
          name: customerName,
        },
      })),
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: averageRatingValue,
        reviewCount: totalReviewCount,
      },
    };
  }

  return (
    <div
      className={clsx('px-0 py-[1.875rem] w-[100%] customer_reviews')}
      style={{ backgroundColor: background ?? 'inherit' }}
    >
      <Script
        id="dynamicJsonLD"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(getReviewStructuredData()),
        }}
        strategy="beforeInteractive"
      />
      {header && (
        <div className="max-w-[69.375rem] mx-auto my-0 px-[1.25rem] pb-[1.875rem]">
          <ParagraphHeader {...header} />
        </div>
      )}
      <div
        className={clsx(
          'max-w-[69.375rem] py-0 px-[0.3125rem] my-0 mx-auto md:px-[1.25rem]',
          'heartIcon'
        )}
      >
        <ReviewData reviews={reviews} />
      </div>
    </div>
  );
}

export default CustomerReviews;
